<div class="relative bg-white" id="contact-us">
  <div class="absolute inset-0">
    <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
  </div>
  <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
      <div class="max-w-lg mx-auto">
        <h2 class="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
          {{ 'contact-us.title' | translate }}
        </h2>
        <dl class="mt-8 text-base leading-6 text-gray-500">
          <div>
            <dt class="sr-only">Postal address</dt>
            <dd>
              <p>
                3 Place Ville-Marie, Suite 400,<br>
                Montréal, QC H3B 2E3
              </p>
            </dd>
          </div>
          <div class="mt-6">
            <dt class="sr-only">Phone number</dt>
            <dd class="flex">
              <!-- Heroicon name: phone -->
              <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
              </svg>
              <!-- <span class="ml-3">
                +1 (800) 908 0052 (ext. 101)
              </span> -->
            </dd>
          </div>
          <div class="mt-3">
            <dt class="sr-only">Email</dt>
            <dd class="flex">
              <!-- Heroicon name: mail -->
              <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
              </svg>
              <span class="ml-3">
                info@objex.tech
              </span>
            </dd>
          </div>
        </dl>
        <!-- <p class="mt-6 text-base leading-6 text-gray-500">
          Looking for careers?
          <a href="https://objex.careers" class="font-medium text-gray-700 underline">View all job openings</a>.
        </p> -->
      </div>
    </div>
    <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div class="max-w-lg mx-auto lg:max-w-none">
        <form *ngIf="!submitted && !error; else submittedTemplate" [formGroup]="form" (ngSubmit)="submit()">
          <div class="block md:flex md:space-x-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>{{ 'contact-us.form.firstName' | translate }}</mat-label>
              <input matInput type="text" autocomplete="given-name" formControlName="firstName">
              <mat-error *ngIf="form.controls.firstName.invalid && form.controls.firstName.hasError('required')">
                {{ 'contact-us.form.firstName' | translate }} field is required
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-full">
              <mat-label>{{ 'contact-us.form.lastName' | translate }}</mat-label>
              <input matInput type="text" autocomplete="family-name" formControlName="lastName">
              <mat-error *ngIf="form.controls.lastName.invalid && form.controls.lastName.hasError('required')">
                {{ 'contact-us.form.lastName' | translate }} field is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="block md:flex md:space-x-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>{{ 'contact-us.form.email' | translate }}</mat-label>
              <input matInput type="email" formControlName="email">
              <mat-error *ngIf="form.controls.email.invalid && form.controls.email.hasError('required')">
                {{ 'contact-us.form.email' | translate }} field is required
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-full">
              <mat-label>{{ 'contact-us.form.phone' | translate }}</mat-label>
              <input matInput type="tel" formControlName="phone">
              <mat-error *ngIf="form.controls.phone.invalid && form.controls.phone.hasError('required')">
                {{ 'contact-us.form.phone' | translate }} field is required
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>{{ 'contact-us.form.message' | translate }}</mat-label>
              <textarea rows="5" matInput formControlName="message"></textarea>
              <mat-error *ngIf="form.controls.message.invalid && form.controls.message.hasError('required')">
                {{ 'contact-us.form.message' | translate }} field is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="mt-2" *ngIf="error">
            <mat-error class="text-sm">{{error}}</mat-error>
          </div>

          <div class="mt-5">
            <span class="inline-flex rounded-md shadow-sm">
              <button type="submit"
                      class="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                {{ 'contact-us.form.submit' | translate }}
              </button>
            </span>
          </div>
        </form>

        <ng-template #submittedTemplate>
          <div class="flex flex-col items-center justify-center h-full">
            <svg class="w-20 h-20 text-green-200" fill="currentColor" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
            </svg>

            <p class="mt-6 font-light text-blue-gray-700 text-3xl">
              {{ 'contact-us.form.success' | translate }}
            </p>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>
