<div class="py-12 bg-white" id="services">
  <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <p
        class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{{ 'services.title' | translate }}</p>
      <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {{ 'services.subtitle' | translate }}
      </h3>
      <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
        {{ 'services.description' | translate }}
      </p>
    </div>


    <div class="mt-10">
      <ul class="grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-12">
        <li>
          <div class="mt-10">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="h-6 w-6 flex items-center justify-center h-12 w-12 rounded-md text-white">
                     <img src="assets/icons/microservices.svg"/>
                </div>
              </div>
              <div class="ml-4">
                <h4
                  class="text-lg leading-6 font-medium text-gray-900">Microservices</h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  In today's fast-paced digital landscape, businesses need software solutions that are highly scalable, flexible, and efficient. Objex is proud to present our Microservices Architecture Service, designed to revolutionize the way you develop and deploy applications. With our expertise, we empower you to build resilient and scalable systems that can adapt to your evolving business needs and velocity.
                </p>
                <div class="mt-3">
                  <a href='/microservices'
                     class="flex items-center text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                    Read More 
                    <svg class="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="mt-10">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="h-6 w-6 flex items-center justify-center h-12 w-12 rounded-md text-white">
                     <img src="assets/icons/cloudnative.svg"/>
                </div>
              </div>
              <div class="ml-4">
                <h4
                  class="text-lg leading-6 font-medium text-gray-900">Gen AI and Cloud Infrastructure</h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  Are you ready to leverage the full potential of Gen AI and cloud computing for your business? Look no further than Objex Cloud Architecture Services. With our expertise in Amazon Web Services (AWS), Microsoft Azure, and Google Cloud, we offer comprehensive solutions tailored to meet your unique business needs. Whether you are a startup, a small business, or an enterprise, we have the knowledge and experience to guide you through the cloud transformation journey.
                </p>
                <div class="mt-3">
                  <a href='/cloud'
                     class="flex items-center text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                    Read More 
                    <svg class="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>      
        <li>
          <div class="mt-10">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="h-6 w-6 flex items-center justify-center h-12 w-12 rounded-md text-white">
                     <img src="assets/icons/apis.svg"/>
                </div>
              </div>
              <div class="ml-4">
                <h4
                  class="text-lg leading-6 font-medium text-gray-900">APIs</h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  In today's digital era, organizations are striving to enhance their digital capabilities and improve customer experiences. Efficient and secure integration of applications and systems has become paramount for success. That's where Objex's API Architecture Service comes in, offering unrivaled expertise in Apigee, AWS API Gateway, and Azure API Gateway to empower our customers with seamless integration solutions.
                </p>
                <div class="mt-3">
                  <a href='/apis'
                     class="flex items-center text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                    Read More 
                    <svg class="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li> 
        <li>
          <div class="mt-10">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="h-6 w-6 flex items-center justify-center h-12 w-12 rounded-md text-white">
                     <img src="assets/icons/commerce.svg"/>
                </div>
              </div>
              <div class="ml-4">
                <h4
                  class="text-lg leading-6 font-medium text-gray-900">mPOS/eCommerce</h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  At Objex, we understand the evolving needs of retail businesses in today's digital landscape. That's why we offer a comprehensive suite of services tailored to meet the unique requirements of retail giants like Lululemon and Aldo. With our expertise in UI, payments, loyalty, inventory, pricing, promotions, couponing, omni and unified commerce, we empower our retail customers to thrive in the competitive online marketplace.
                </p>
                <div class="mt-3">
                  <a href='/ecommerce'
                     class="flex items-center text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                    Read More 
                    <svg class="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>                    
      </ul>
    </div>


  </div>
</div>

